.fileInput {
    border-bottom: 4px solid #d3d3d3;
    border-right: 4px solid #d3d3d3;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
}

.imgPreview {
    text-align: center;
    margin-top: 15px;
    width: 100%;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-top: 5px solid #808080;
    border-bottom: 5px solid #808080;
}

.imgPreview img {
    width: 100%;
}

.previewText {
    width: 100%;
    margin: 20px 0;
}

.submitButton {
    padding: 12px;
    margin-left: 10px;
    /* background: #fff; */
    border: 4px solid #d3d3d3;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
}

.submitButton:hover {
    background: #efefef;
}